/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InviteBusinessUserDTO { 
    fullName: string;
    email: string;
    productIds?: Array<string> | null;
    grantAdminAccess?: boolean;
}

