import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../utils/utils';

@Pipe({
  name: 'fileUrl'
})
export class FileUrlPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return Utils.getFileUrl(value as number);
  }
}
