import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {
  transform(value: string, start = 0, end = -4, spacer = 0, ...args: unknown[]): unknown {
    if (!value) {
      return value;
    }
    const endChars = value.slice(end);
    const maskedChars = value.slice(start, end).replace(/./g, '*');
    return (maskedChars + endChars).replace(new RegExp(`(.{${spacer}})`, 'g'), '$1 ');
  }
}
