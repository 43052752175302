import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileInputDirective } from './file-input/file-input.directive';
import { NairaSignDirective } from './naira-sign/naira-sign.directive';
import { DragNDropDirective } from './drag-n-drop.directive';

const directives = [
  FileInputDirective,
  NairaSignDirective,
  DragNDropDirective,
]
@NgModule({
  declarations: [
    directives
  ],
  imports: [
    CommonModule
  ],
  exports: [
    directives
  ]
})
export class DirectivesModule {}
