import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {XAlertMessage} from "../../../shared/components/alert-message/alert-message.component";
import {AccountService, ResetPasswordDTO} from "../../../../../sdk/figoseal-api-sdk";
import {Router} from "@angular/router";
import {HelperService} from "../../../services/helper.service";
import {ConfirmFieldValidator} from "../../../shared/validators/confirm-field-validator";
import {Utils} from "../../../shared/utils/utils";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    form: FormGroup;
    xAlertMessage: XAlertMessage = {
        message: undefined,
        messageType: 'info'
    };
    isResettingPassword: boolean;
    passwordFieldType: string = 'password';
    passwordConfFieldType: string = 'password';

    constructor(private fb: FormBuilder, private accountService: AccountService,
                private router: Router, private helperService: HelperService) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            code: ['', [Validators.required, Validators.minLength(Utils.NAME_FIELD_MIN_LENGTH), Validators.maxLength(Utils.NAME_FIELD_MAX_LENGTH)]],
            password: ['', [Validators.required, Validators.pattern(Utils.PASSWORD_REGEX)]],
            confPassword: ['', [Validators.compose([
                Validators.required, Validators.minLength(2), Validators.maxLength(50),
                ConfirmFieldValidator.matchField('password', 'passwordMismatch')
            ])]]
        });
    }

    togglePasswordVisibility() {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    }

    toggleConfPasswordVisibility() {
        this.passwordConfFieldType = this.passwordConfFieldType === 'password' ? 'text' : 'password';
    }

    submit() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        const savedEmailData: any = localStorage.getItem('ForgotPasswordComponent') ? JSON.parse(localStorage.getItem('ForgotPasswordComponent')) : '';

        if(!savedEmailData){
            this.showMessage("Error in getting email", 'danger');
        }

        this.isResettingPassword = true;
        const resetPasswordDTO: ResetPasswordDTO = {
            email: savedEmailData,
            password: this.form.get('password').value,
            confirmPassword: this.form.get('confPassword').value,
            code: this.form.get('code').value
        }
        this.accountService.accountResetPasswordPost({resetPasswordDTO: resetPasswordDTO}).subscribe({
            next: (v) => {
                this.showMessage("You password has been reset successfully", 'success');
                this.isResettingPassword = false;
                setTimeout(() => {
                    this.router.navigate(['/login']);
                }, 1200);
            }, error: (err: unknown) => {
                this.isResettingPassword = false;
                this.showMessage(this.helperService.getError(err), 'danger');
            }
        })
    }

    showMessage(error: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
        this.xAlertMessage.messageType = type;
        this.xAlertMessage.message = error;
        setTimeout(() => {
            this.xAlertMessage.message = undefined;
        }, 4000);
    }
}
