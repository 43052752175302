import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccountService, LoginDTO} from "../../../../../sdk/figoseal-api-sdk";
import {XAlertMessage} from "../../../shared/components/alert-message/alert-message.component";
import {HelperService} from "../../../services/helper.service";
import {Router} from "@angular/router";
import {Utils} from "../../../shared/utils/utils";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    xAlertMessage: XAlertMessage = {
        message: undefined,
        messageType: 'info'
    };
    form: FormGroup;
    passwordFieldType: string = 'password';
    isAuthenticating: boolean;

    constructor(private fb: FormBuilder,
                private accountService: AccountService,
                private helperService: HelperService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(Utils.EMAIL_REGEX)]],
            password: ['', [Validators.required]]
        });
    }

    doLogin(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isAuthenticating = true;

        const loginDto: LoginDTO = {
            email: this.form.get('email').value.toString().trim(),
            password: this.form.get('password').value
        }
        this.accountService.accountLoginPost({loginDTO: loginDto}).subscribe({
            next: (v) => {
                //TODO: Update After login router
                this.router.navigate(['']);
                this.isAuthenticating = false;
            },
            error: (error: unknown) => {
                this.isAuthenticating = false;
                this.showMessage(this.helperService.getError(error), 'danger');
            }
        });
    }

    togglePasswordVisibility() {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    }

    showMessage(error: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
        this.xAlertMessage.messageType = type;
        this.xAlertMessage.message = error;
        setTimeout(() => {
            this.xAlertMessage.message = undefined;
        }, 4000);
    }

}
