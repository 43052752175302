// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:host {
  color: #f44336;
  font-size: 10px;
  display: block;
  font-size: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/field-error/field-error.component.css"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,eAAe;EACf,cAAc;EACd,eAAe;AACjB","sourcesContent":["\n:host {\n  color: #f44336;\n  font-size: 10px;\n  display: block;\n  font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
