import { AbstractControl, ValidatorFn } from '@angular/forms';

export class ConfirmFieldValidator {

    static ERROR_NAME = 'mismatch';

    public static matchField(name: string, errorName?: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {

            if (!control.value || !control.parent) {
                return null;
            }

            const otherField = control.parent.get(name);
            if (!otherField || !otherField.value) {
                return null;
            }
            const error = {};
            error[errorName || ConfirmFieldValidator.ERROR_NAME] = control.value;
            return otherField.value !== control.value ? error : null;
        };
    }
}
