import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Utils} from "../../../../shared/utils/utils";
import {CountryISO} from "ngx-intl-tel-input";
import {NameValueVM, UtilityService} from "../../../../../../sdk/figoseal-api-sdk";
import {XAlertMessage} from "../../../../shared/components/alert-message/alert-message.component";
import {HelperService} from "../../../../services/helper.service";

@Component({
  selector: 'app-sign-up-ii',
  templateUrl: './sign-up-ii.component.html',
  styleUrls: ['./sign-up-ii.component.css']
})
export class SignUpIiComponent implements OnInit{
  @Output()
  emitFormGroupTwo = new EventEmitter<FormGroup>();
  form: FormGroup;
  @Output()
  emitBackButtonClicked = new EventEmitter<any>();
  selectedCountryISO: CountryISO = CountryISO.Nigeria;
  businessTypesList: Array<NameValueVM> = [];
  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  constructor(private fb: FormBuilder,
              private utilityService: UtilityService,
              private helperService: HelperService) {}


  ngOnInit(): void {

    this.form = this.fb.group({
      businessName: ['', [Validators.required,  Validators.minLength(Utils.NAME_FIELD_MIN_LENGTH), Validators.maxLength(Utils.NAME_FIELD_MAX_LENGTH)]],
      businessType: ['', [Validators.required]],
      rcNumber: ['', [Validators.required]],//TODO: Validate RC Number
      phoneNumber: ['', [Validators.required]]
    });

    this.getBusinessTypes();
  }

  getBusinessTypes(){
    this.utilityService.utilityBusinessTypesGet().subscribe({
      next:(v)=>{
        this.businessTypesList = v;
      }, error: (err) => {
        this.showMessage(this.helperService.getError(err), 'danger');
      }
    })
  }

  emitNextSteps(){
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }
    this.emitFormGroupTwo.emit(this.form);
  }


  showMessage(error: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = error;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }
}
