/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ChangePasswordVM { 
    token?: string | null;
    fullName?: string | null;
    email?: string | null;
    phone?: string | null;
    expDate?: string;
    roles?: Array<string> | null;
    avatar?: string | null;
    userId?: string | null;
    forcePasswordChange?: boolean;
}

