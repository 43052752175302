import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Utils} from "../../../../shared/utils/utils";
import {ConfirmFieldValidator} from "../../../../shared/validators/confirm-field-validator";

@Component({
    selector: 'app-sign-up-i',
    templateUrl: './sign-up-i.component.html',
    styleUrls: ['./sign-up-i.component.css']
})
export class SignUpIComponent implements OnInit {

    form: FormGroup;
    @Output()
    emitFormGroupOne = new EventEmitter<any>();
    passwordFieldType: string = 'password';
    passwordConfFieldType: string = 'password';

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(Utils.EMAIL_REGEX)]],
            fullName: ['', [Validators.required, Validators.maxLength(Utils.NAME_FIELD_MAX_LENGTH), Validators.minLength(Utils.NAME_FIELD_MIN_LENGTH)]],
            password: ['', [Validators.required, Validators.pattern(Utils.PASSWORD_REGEX)]],
            confPassword: ['', [Validators.compose([
                Validators.required, Validators.minLength(2), Validators.maxLength(50),
                ConfirmFieldValidator.matchField('password', 'passwordMismatch')
            ])]]
        });

    }

    togglePasswordVisibility() {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    }

    toggleConfPasswordVisibility() {
        this.passwordConfFieldType = this.passwordConfFieldType === 'password' ? 'text' : 'password';
    }

    emitNextSteps(){
        if(this.form.invalid){
            this.form.markAllAsTouched();
            return;
        }
        this.emitFormGroupOne.emit(this.form);
    }
}
