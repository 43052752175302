import {Component, OnInit} from '@angular/core';
import {XAlertMessage} from "../../../shared/components/alert-message/alert-message.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccountService} from "../../../../../sdk/figoseal-api-sdk";
import {HelperService} from "../../../services/helper.service";
import {Router} from "@angular/router";
import {Utils} from "../../../shared/utils/utils";
import {PageManager} from "../../../services/page-manager";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    xAlertMessage: XAlertMessage = {
        message: undefined,
        messageType: 'info'
    };
    form: FormGroup;
    isSendingEmail: boolean;

    constructor(private fb: FormBuilder,
                private helperService: HelperService,
                private accountService: AccountService,
                private router: Router,
                private pageManager: PageManager) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(Utils.EMAIL_REGEX)]]
        })
    }

    submit() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        this.isSendingEmail = true;
        this.accountService.accountResendActivationEmailEmailPost({email: this.form?.get('email')?.value}).subscribe({
            next: (v) => {
                this.showMessage("Email has been sent successfully", 'success');
                this.isSendingEmail = false;
                this.pageManager.storeData('ForgotPasswordComponent', 'reset', this.form.get('email').value);
                setTimeout(() => {
                    this.router.navigate(['/reset-password']);
                }, 1200);
            }, error: (err: unknown) => {
                this.isSendingEmail = false;
                this.showMessage(this.helperService.getError(err), 'danger');
            }
        })
    }

    showMessage(error: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
        this.xAlertMessage.messageType = type;
        this.xAlertMessage.message = error;
        setTimeout(() => {
            this.xAlertMessage.message = undefined;
        }, 4000);
    }

}
