import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NameValueVM, UtilityService} from "../../../../../../sdk/figoseal-api-sdk";
import {XAlertMessage} from "../../../../shared/components/alert-message/alert-message.component";
import {Utils} from "../../../../shared/utils/utils";
import {HelperService} from "../../../../services/helper.service";

@Component({
    selector: 'app-sign-up-iii',
    templateUrl: './sign-up-iii.component.html',
    styleUrls: ['./sign-up-iii.component.css']
})
export class SignUpIiiComponent implements OnInit, OnChanges {
    @Output()
    emitFormGroupThree = new EventEmitter<any>();
    form: FormGroup;
    @Output()
    emitBackButtonClicked = new EventEmitter<any>();
    @Input() submittingForm!: boolean;
    xAlertMessage: XAlertMessage = {
        message: undefined,
        messageType: 'info'
    };
    countryList: Array<NameValueVM> = [];
    stateList: Array<NameValueVM> = [];


    constructor(private fb: FormBuilder,
                private utilityService: UtilityService,
                private helperService: HelperService) {
    }

    ngOnInit(): void {

        this.form = this.fb.group({
            countryCode: ['', [Validators.required]],
            stateCode: ['', [Validators.required]],
            address: ['', [Validators.required, Validators.maxLength(Utils.ADDRESS_FIELD_MAX_LENGTH), Validators.minLength(Utils.ADDRESS_FIELD_MIN_LENGTH)]],
            acceptTerms: [false, Validators.requiredTrue]
        });

        this.getCountry();

        this.form.get('countryCode').valueChanges.subscribe(x => {
            this.getStates(x);
        })
    }

    emitNextSteps() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        this.emitFormGroupThree.emit(this.form);
    }

    getCountry(){
        this.utilityService.utilityCountriesGet().subscribe({
            next:(v)=>{
                this.countryList = v;
            }, error: (err) => {
                this.showMessage(this.helperService.getError(err), 'danger');
            }
        })
    }

    getStates(countryName: string){
        this.utilityService.utilityStatesCountryGet({country: countryName}).subscribe({
            next:(v)=>{
                this.stateList = v;
            }, error: (err) => {
                this.showMessage(this.helperService.getError(err), 'danger');
            }
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.submittingForm = changes['submittingForm'] && changes['submittingForm']?.currentValue ?
            changes['submittingForm'].currentValue: false;
    }

    showMessage(error: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
        this.xAlertMessage.messageType = type;
        this.xAlertMessage.message = error;
        setTimeout(() => {
            this.xAlertMessage.message = undefined;
        }, 4000);
    }
}
