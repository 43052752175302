import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.css']
})
export class AlertMessageComponent {
  constructor(private sanitizer: DomSanitizer) {}

  _sampleIcon = this.sanitizer.bypassSecurityTrustHtml(
    '<i class="iconoir-info-circle-solid ts-20"></i>'
  );

  _message: string | undefined;
  _icon: SafeHtml | undefined;
  _messageType: 'primary' | 'success' | 'danger' | 'warning' | 'info' = 'primary';

  @Input()
  set messageType(type: 'primary' | 'success' | 'danger' | 'warning' | 'info') {
    this._messageType = type;
  }

  get messageType(): 'primary' | 'success' | 'danger' | 'warning' | 'info' {
    return this._messageType || 'info';
  }

  @Input()
  set messageTypeThroughEnum(type: MessageTypeConstant) {
    this._messageType = type;
  }

  @Input()
  set message(message: string | undefined) {
    this._message = message;
  }

  get message(): string {
    return this._message!;
  }
}

export interface XAlertMessage {
  message: string;
  icon?: SafeHtml;
  messageType: 'primary' | 'success' | 'danger' | 'warning' | 'info';
}

export enum MessageTypeConstant {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info'
}
