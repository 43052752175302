export interface MinFileSizeRestriction {
  min: number;
}

export interface MaxFileSizeRestriction {
  max: number;
}

export interface RangeFileSizeRestrictions {
  min: number;
  max: number;
}

export type FileSizeRestrictions =
  | MinFileSizeRestriction
  | MaxFileSizeRestriction
  | RangeFileSizeRestrictions;

/**
 *
 * @param value
 */
export function isMinFileSizeRestrictions(value: any): value is MinFileSizeRestriction {
  return Object.prototype.hasOwnProperty.call(value, 'min');
}

/**
 *
 * @param value
 */
export function isMaxFileSizeRestrictions(value: any): value is MaxFileSizeRestriction {
  return Object.prototype.hasOwnProperty.call(value, 'max');
}

/**
 *
 * @param value
 */
export function isRangeFileSizeRestrictions(value: any): value is RangeFileSizeRestrictions {
  return (
    Object.prototype.hasOwnProperty.call(value, 'min') &&
    Object.prototype.hasOwnProperty.call(value, 'max')
  );
}
