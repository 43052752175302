import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {Constant} from "../../../constants/constant";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nairaSign]',
  providers: [CurrencyPipe]
})
export class NairaSignDirective implements OnChanges {
  @Input() currencyValue = 0;
  @Input() label = '';

  constructor(private elRef: ElementRef, private currencyPipe: CurrencyPipe) {}

  get NairaSign(): string {
    return Constant.nairaSign;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const label = changes['label']?.currentValue ? changes['label'].currentValue : this.label;
    this.elRef.nativeElement.innerText = `${label} ${this.currencyPipe.transform(
      changes['currencyValue'].currentValue,
      this.NairaSign
    )}`;
  }
}
