/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FilterVM { 
    query?: string | null;
    page?: number;
    pageLimit?: number;
}

