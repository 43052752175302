/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentStatus = 1 | 2 | 3 | 4;

export const DocumentStatus = {
    NUMBER_1: 1 as DocumentStatus,
    NUMBER_2: 2 as DocumentStatus,
    NUMBER_3: 3 as DocumentStatus,
    NUMBER_4: 4 as DocumentStatus
};

