import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from "@angular/material/stepper";
import {XAlertMessage} from "../../../shared/components/alert-message/alert-message.component";
import {FormGroup} from "@angular/forms";
import {AccountService, BusinessSignupDTO, NameValueVM, UtilityService} from "../../../../../sdk/figoseal-api-sdk";
import {HelperService} from "../../../services/helper.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {
    SignUpSuccessModalComponent
} from "../../../shared/components/sign-up-success-modal/sign-up-success-modal.component";
import {Router} from "@angular/router";


@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit, AfterViewInit {
    @ViewChild('stepper') public myStepper: MatStepper;

    xAlertMessage: XAlertMessage = {
        message: undefined,
        messageType: 'info'
    };
    private formOneGroup: FormGroup;
    private formTwoGroup: FormGroup;
    private formThreeGroup: FormGroup;
    submittingForm: boolean;

    constructor(private accountService: AccountService,
                private utilityService: UtilityService,
                private helperService: HelperService,
                private modalService: BsModalService,
                private router: Router) {
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {

    }

    // Stepper Number is the index of the components ranging from 0 to 2
    getFormData($event: FormGroup, stepper: MatStepper): void {
        const stepNumber = stepper.selectedIndex;

        if ($event) {
            if (stepNumber == 0) {
                this.formOneGroup = $event;
            } else if (stepNumber == 1) {
                this.formTwoGroup = $event;
            } else if (stepNumber == 2) {
                this.formThreeGroup = $event;
                this.submitData();
            } else {
                this.showMessage('Invalid Step Number', 'danger');
            }
            stepper.next();
        }
    }


    submitData() {
        this.submittingForm = true;
        const dateOne = this.formOneGroup.getRawValue();
        const dateTwo = this.formTwoGroup.getRawValue();
        const dateThree = this.formThreeGroup.getRawValue();

        const businessSignUpDto: BusinessSignupDTO = {
            email: dateOne?.email,
            password: dateOne?.password,
            confirmPassword: dateOne?.confPassword,
            phoneNumber: dateTwo?.phoneNumber?.number,
            fullName: dateOne?.fullName,
            businessName: dateTwo?.businessName,
            businessType: dateTwo?.businessType,
            rcNumber: dateTwo?.rcNumber,
            country: dateThree?.countryCode,
            state: dateThree?.stateCode,
            address: dateThree?.address
        }

        this.accountService.accountBusinessRegisterPost({businessSignupDTO: businessSignUpDto}).subscribe({
            next: (v) => {
                this.submittingForm = false;
                this.signUpSuccessModal();
            }, error: (err: unknown) => {
                this.submittingForm = false;
                this.showMessage(this.helperService.getError(err), 'danger');
            }
        })
    }

    getFormTwoPreviousStepClicked(stepper: MatStepper): void {
        stepper.previous();
    }

    getFormThreePreviousStepClicked(stepper: MatStepper): void {
        stepper.previous();
    }

    showMessage(error: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
        this.xAlertMessage.messageType = type;
        this.xAlertMessage.message = error;
        setTimeout(() => {
            this.xAlertMessage.message = undefined;
        }, 4000);
    }

    signUpSuccessModal(){
        const bsModal = this.modalService.show(SignUpSuccessModalComponent, {
            initialState: {
                email: this.formOneGroup?.getRawValue()?.email
            },
            backdrop: 'static',
            class: 'modal-lg modal-dialog-centered',
            keyboard: false
        });
        bsModal.content.cancel.subscribe(() => {
            bsModal.hide();
            this.router.navigate(['/login']);
        })
    }

}
