/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateBusiness { 
    email?: string | null;
    phoneNumber: string;
    fullName?: string | null;
    businessName?: string | null;
    businessType?: string | null;
    rcNumber?: string | null;
    country?: string | null;
    state?: string | null;
    address?: string | null;
}

