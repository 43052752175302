/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaginationVM { 
    totalData?: number;
    pageLimit?: number;
    currentPage?: number;
    currentPageData?: number;
    totalPages?: number;
    prevPage?: number | null;
    nextPage?: number | null;
    skip?: number;
}

