export * from './account.service';
import { AccountService } from './account.service';
export * from './admin.service';
import { AdminService } from './admin.service';
export * from './business.service';
import { BusinessService } from './business.service';
export * from './product.service';
import { ProductService } from './product.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './utility.service';
import { UtilityService } from './utility.service';
export const APIS = [AccountService, AdminService, BusinessService, ProductService, RoleService, UserService, UtilityService];
