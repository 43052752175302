import { Directive, ElementRef, HostListener, Input, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import {
  FileSizeRestrictions,
  isMaxFileSizeRestrictions,
  isMinFileSizeRestrictions,
  MaxFileSizeRestriction,
  MinFileSizeRestriction
} from './file-size-restriction';

type HTMLFileInputMultipleAttribute = any | boolean;

interface FileInputEventTarget extends EventTarget {
  files: FileList;
}

@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    '[fileInput][formControlName],[fileInput][fileSize],[fileInput][ngModel]',
  exportAs: 'fileSizeDirective',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: FileInputDirective,
      multi: true
    }
  ]
})
export class FileInputDirective {
  /**
   * @type {boolean}
   * @public
   */
  @Input()
  public fileSize!: FileSizeRestrictions;

  /**
   * @type {string}
   * @public
   */
  @Input()
  public fileSizeErrorMsg = 'File size is invalid';
  /**
   * @type {ElementRef}
   * @private
   */
  private _element: ElementRef;
  /**
   * @type {AbstractControl}
   * @private
   */
  private _control!: AbstractControl;
  /**
   *
   * @type {{}}
   * @private
   */
  private _oldValues: FileSizeRestrictions = {
    min: 0,
    max: 0
  };

  /**
   *
   * @param {ElementRef} element
   * @returns {void}
   * @public
   */
  public constructor(element: ElementRef) {
    this._element = element;
  }

  /**
   * @type {boolean}
   * @private
   */
  private _multiple = false;

  /**
   * @returns {boolean}
   */
  public get multiple(): HTMLFileInputMultipleAttribute {
    return this._multiple;
  }

  /**
   * @type {boolean}
   * @private
   */
  @Input()
  public set multiple(value: any) {
    this._multiple = value === '' || !!value;
  }

  /**
   *
   * @returns {void}
   * @public
   */
  public ngOnInit(): void {
    this._validateElement();
  }

  /**
   *
   * @returns {void}
   * @param {SimpleChanges} changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    // error message has been changed
    if (changes['fileSizeErrorMsg'] && !changes['fileSizeErrorMsg'].firstChange) {
      this._setValidity(this._getInputValue(this._element.nativeElement as FileInputEventTarget));
    }
  }

  /**
   *
   * @public
   * @returns {void}
   */
  public ngDoCheck(): void {
    if (this._control && this.fileSize) {
      let changeDetected = false;

      if (
        isMinFileSizeRestrictions(this.fileSize) &&
        isMinFileSizeRestrictions(this._oldValues) &&
        this.fileSize.min !== this._oldValues.min
      ) {
        changeDetected = true;
        (this._oldValues as MinFileSizeRestriction).min = this.fileSize.min;
      }

      if (
        isMaxFileSizeRestrictions(this.fileSize) &&
        isMaxFileSizeRestrictions(this._oldValues) &&
        this.fileSize.max !== this._oldValues.max
      ) {
        changeDetected = true;
        (this._oldValues as MaxFileSizeRestriction).max = this.fileSize.max;
      }

      if (changeDetected) {
        this._setValidity(this._getInputValue(this._element.nativeElement as FileInputEventTarget));
      }
    }
  }

  /**
   *
   * @param {FormControl} control
   * @returns {ValidationErrors}
   * @private
   */
  public validate(control: AbstractControl): ValidationErrors | null {
    if (!this._control) {
      this._control = control;
    }
    if (this._hasError(this._control.value)) {
      return {
        size: this.fileSizeErrorMsg
      } as ValidationErrors;
    }
    return null;
  }

  /**
   *
   * @param {EventTarget} eventTarget
   * @returns {void}
   */
  @HostListener('change', ['$event.target'])
  public onChange(eventTarget: EventTarget): void {
    const value: File | FileList | undefined = this._getInputValue(
      eventTarget as FileInputEventTarget
    );
    this._control?.setValue(value, {
      onlySelf: true,
      emitEvent: false,
      emitModelToViewChange: false,
      emitViewToModelChange: false
    });
    this._setValidity(value);
  }

  /**
   *
   * @param value
   * @private
   */
  private _setValidity(value: File | FileList | undefined): void {
    const errors: ValidationErrors = Object.assign({}, this._control?.errors);
    if (this._hasError(value)) {
      errors['size'] = this.fileSizeErrorMsg;
    } else {
      if (this._control?.hasError('size')) {
        delete errors['size'];
      }
    }

    this._control?.setErrors(Object.keys(errors).length ? errors : null);
  }

  /**
   *
   * @param {File|FileList|undefined} value
   * @returns {boolean}
   * @private
   */
  private _hasError(value: File | FileList | undefined): boolean {
    return this.fileSize && !this._hasValidSize(value);
  }

  /**
   *
   * @param {File|FileList} value
   * @returns {boolean}
   * @private
   */
  private _hasValidSize(value: File | FileList | undefined): boolean {
    let valid = true;

    if (value) {
      if (this.multiple && !!(value as FileList).length) {
        value = value as FileList;

        // tslint:disable-next-line
        for (let i = 0, length = value.length; i < length; i++) {
          const file: File | null = value.item(i);

          if (!this._validateSize(file)) {
            valid = false;
            break;
          }
        }
      } else {
        valid = this._validateSize(value as File | undefined);
      }
    }

    return valid;
  }

  /**
   *
   * @param value
   * @returns {boolean}
   * @private
   */
  private _validateSize(value: File | undefined | null): boolean {
    let valid = true;
    if (value) {
      const isMin =
        isMinFileSizeRestrictions(this.fileSize) && this.fileSize.min
          ? value.size >= this.fileSize.min
          : true;
      const isMax =
        isMaxFileSizeRestrictions(this.fileSize) && this.fileSize.max
          ? value.size <= this.fileSize.max
          : true;

      valid = isMin && isMax;
    }

    return valid;
  }

  /**
   *
   * @param {FileInputEventTarget} eventTarget
   * @returns {File|FileList|undefined}
   * @private
   */
  private _getInputValue(eventTarget: FileInputEventTarget): File | FileList | undefined {
    return this.multiple ? eventTarget.files : (eventTarget.files.item(0) as any);
  }

  /**
   *
   * @throws {Error}
   * @private
   */
  private _validateElement(): void {
    const elemType: string = this._element.nativeElement.tagName;
    const inputType: string = this._element.nativeElement.getAttribute('type');

    if (elemType !== 'INPUT') {
      throw new Error(`Ng2FileSizeDirective: DOM element must be input, not ${elemType}`);
    }

    if (inputType !== 'file') {
      throw new Error(`Ng2FileSizeDirective: input must be type of "file", not "${inputType}"`);
    }
  }
}
