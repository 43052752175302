/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BusinessType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;

export const BusinessType = {
    NUMBER_0: 0 as BusinessType,
    NUMBER_1: 1 as BusinessType,
    NUMBER_2: 2 as BusinessType,
    NUMBER_3: 3 as BusinessType,
    NUMBER_4: 4 as BusinessType,
    NUMBER_5: 5 as BusinessType,
    NUMBER_6: 6 as BusinessType,
    NUMBER_7: 7 as BusinessType,
    NUMBER_8: 8 as BusinessType,
    NUMBER_9: 9 as BusinessType,
    NUMBER_10: 10 as BusinessType,
    NUMBER_11: 11 as BusinessType,
    NUMBER_12: 12 as BusinessType,
    NUMBER_13: 13 as BusinessType,
    NUMBER_14: 14 as BusinessType
};

