import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-sign-up-success-modal',
  templateUrl: './sign-up-success-modal.component.html',
  styleUrls: ['./sign-up-success-modal.component.css']
})
export class SignUpSuccessModalComponent {

  @Input()
  email: string;

  @Output()
  cancel = new EventEmitter();

  dismissClick() {
    this.cancel.emit()
  }
}
